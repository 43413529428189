import { useState,createContext,useCallback } from "react"
import { axiosPrivate } from "../api/axios";
import useAlerts from "../Hooks/useAlerts";

const FriendsContext = createContext();

export const FriendsProvider = ({children}) =>{
    const [friends, setFriends] = useState([]);
    const [pendingFriendsInvitations, setPendingFriendsInvitations] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);

    const {openAlertMessage} = useAlerts();

    // add new invitation into array. Usecallbackt to cache the function
    const addPendingFriendsInvitations = useCallback((newPendingFriendsInvitations) => {
        setPendingFriendsInvitations(newPendingFriendsInvitations);
    },[setPendingFriendsInvitations]);

    const removePendingFriendsInvitations = (oldId) => {
        setPendingFriendsInvitations(prevFriends => prevFriends.filter(friend=>friend.id!==oldId));
    };

    // add new friend into array
    const addFriends = useCallback((newFriends) => {
        setFriends(newFriends);
    },[setFriends]);

    // add new online user into array
    const addOnlineUsers = (newOnlineFriends) =>{
        setOnlineUsers(newOnlineFriends);
    };
    
    const sendFriendInvitation = async (data, closeDiaglogHandler) =>{
        try {
            await axiosPrivate.post("/invitations/invite", data);
            console.log("sending friend invitation");
            openAlertMessage("Invitation has been sent!");
            closeDiaglogHandler();
        } 
        catch (err) {
            // openAlertMessage(err);
            openAlertMessage(err.response.data);
            console.log(err);
        }
    }

    const acceptFriendInvitation = async (data) => {
        try {
            await axiosPrivate.post("/invitations/accept", data);
            openAlertMessage("Invitation accepted");
        } 
        catch (err) {
            openAlertMessage(err.response.data);
            console.log(err);
        }
    }

    const rejectFriendInvitation = async (data) => {
        try {
            await axiosPrivate.post("/invitations/reject", data);
            openAlertMessage("Invitation rejected");
        } 
        catch (err) {
            openAlertMessage(err.response.data);
            console.log(err);
        }
    }

    return(
        <FriendsContext.Provider value = {{friends, pendingFriendsInvitations, onlineUsers, addPendingFriendsInvitations, addFriends, addOnlineUsers, sendFriendInvitation, acceptFriendInvitation, rejectFriendInvitation, removePendingFriendsInvitations}}>
            {children}
        </FriendsContext.Provider>
    )
}

export default FriendsContext;