import { styled } from "@mui/system";
import React from 'react'
import { DropdownMenu } from "../Dropdown/DropdownMenu";
import { Box, Typography } from "@mui/material";
import useChat from "../../../Hooks/useChat"
import useMediaQuery from '@mui/material/useMediaQuery';
import { ArrowBackButton } from "../Button/ArrowBackButton";
import { GroupIconButton } from "../Button/GroupIconButton";

const MainContainer = styled("div")({
  height: "4%",
  minHeight: "48px",
  borderBottom: "1px solid black",
  backgroundColor: "#36393f",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0 15px",
});

export const AppBar = () => {
  const phoneWidth = useMediaQuery('(min-width:600px)');
  const { chosenChat } = useChat();

  return (
    <MainContainer>
      <Box>
        {/* Arrow back to clear chosen chat and go to dm selection menu */}
        {!phoneWidth && chosenChat && <ArrowBackButton/>}
        {/* GroupIcon to show the group members */}
        {!phoneWidth && (chosenChat?.type === "GROUP") && <GroupIconButton/>}
      </Box>
      <ChosenOptionLabel />
      <DropdownMenu />
    </MainContainer>
  )
}

const ChosenOptionLabel = () =>{
  const { chosenChat } = useChat();
  const name = chosenChat?.name;
  return(
    <Typography
      sx={{fontSize: "16px", color:"white", fontWeight:"bold"}}
    >
      {`${name ? name : ""}`}
    </Typography>
  )
}

