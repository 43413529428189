import { useState,createContext } from "react"
import { axiosPrivate } from "../api/axios";
import useAlerts from "../Hooks/useAlerts";

const ChatsContext = createContext();

export const ChatsProvider = ({children}) =>{
    const [chosenChat, setChosenChat] = useState(null);
    const [chatType, setChatType] = useState(null);
    const [messages, setMessages] = useState();
    const [availableRooms, setAvailableRooms] = useState([]);
    const [participants, setParticipants] = useState([]);
    const {openAlertMessage} = useAlerts();
    const SetChosenChat = (chatDetails) =>{
        // store selected chat details, type. Empty the message array as we dont want previous selected message]
        setChosenChat(chatDetails);
        setChatType(chatDetails.type);
        setMessages([]);
    }

    const createGroup = async (data, closeDiaglogHandler) =>{
        try {
            await axiosPrivate.post("/conversation/create", data);
            console.log("sending group invitation");
            openAlertMessage("Group created successfully");
            closeDiaglogHandler();
        } 
        catch (err) {
            // openAlertMessage(err);
            openAlertMessage(err.response.data);
            console.log(err);
        }
    }
    return(
        <ChatsContext.Provider value = {{SetChosenChat,setChatType, setChosenChat, setMessages, chosenChat, chatType, messages,availableRooms, setAvailableRooms,participants, setParticipants, createGroup}}>
            {children}
        </ChatsContext.Provider>
    )
}

export default ChatsContext;
