import { Button } from '@mui/material'
import React from 'react'

export const CustomPrimaryButton = ({
    label,
    additionalStyles,
    disabled,
    onClick,
  }) => {
  return (
    <Button
        variant="contained"
        sx={{
            bgcolor: "#5865F2",
            color: "white",
            textTransform: "none",
            fontSize: "0.8rem",
            fontWeight: 500,
            width: "100%",
            height: "45px",
            padding: "0.8rem",
        }}
        style={additionalStyles ? additionalStyles : {}}
        disabled={disabled}
        onClick={onClick}
    >
        {label}
    </Button>
  )
}
