import { Outlet, Navigate } from "react-router-dom";
import useUser from "../Hooks/useUser";

export const PrivateRoute = () => {
  const { isLoggedin,isLoading } = useUser();
  if(isLoading){
    return <div>isLoading</div>
  }

  return (isLoggedin === true ? <Outlet /> : <Navigate to="/auth/login" />);
};