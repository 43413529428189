import { useState } from 'react'
import { CustomPrimaryButton } from '../Button/CustomPrimaryButton'
import { InviteToGroupDialog } from '../Dialog/InviteToGroupDialog'

export const InviteFriendButton = () =>{
    const additionalStyles = {
      width: '80%',
      background: '#3ba55d',
    }
    const [isDialogOpen, setIsDialogOpen] = useState(false);
  
    const handleOpenAddFriendDialog = () => {
      setIsDialogOpen(true);
    };
  
    const handleCloseAddFriendDialog = () =>{
      setIsDialogOpen(false);
    }
  
    return (
      <>
        <CustomPrimaryButton 
          additionalStyles={additionalStyles}
          label="Invite Friend"
          onClick={handleOpenAddFriendDialog}
        />
        <InviteToGroupDialog
          isDialogOpen = {isDialogOpen}
          closeDialogHandler = {handleCloseAddFriendDialog}
        />
      </>
    )
  }