import { useForm } from "react-hook-form"
import { Link,useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useUser from "../Hooks/useUser";

export default function Signin() {
    //Get our custom hook from AccountContext
    const { SetIsLoggedin }  = useUser();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError
      } = useForm();

    const onSubmit = async (data) => {
        try{
            //data have email and password
            const response = await axios.post("auth/login",
            JSON.stringify(data),
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
            }
          );
          SetIsLoggedin(true);
          console.log(response);
          window.location.href = "/";
        }
        catch(error){
            console.log(error);
            setError("password", {
                type: "manual",
                message: error?.response?.data?.error
            })
        }
    };


    return (
    <div className = "Signin">
        <h1 className = "Signin--h1"> Welcome to MessageMe</h1>
        <form className="Signin--form" onSubmit={handleSubmit(onSubmit)}>
            <label>Email</label>
            <input className="Signin--text" type="email" name="email" placeholder="Enter your email" {...register("email", { required: true, maxLength: 20 })}/>
            {errors?.email?.type === "required" && <p className="Signin--error">This field is required</p>}

            <label>Password</label>
            <input className="Signin--text" type="password" name="password" placeholder="Enter your password" {...register("password", { required: true, minLength:8})}/>
            {errors?.password?.type === "required" && <p className="Signin--error">This field is required</p>}
            {errors?.password?.type === "minLength" && <p className="Signin--error">Your Password must be more than 8 characters</p>}
            {errors?.password?.message && <p className="Signin--error">{errors?.password?.message}</p>}


            <Link to="/auth/register">Forgot Password?</Link>
            <input className="Signin--submit"type="submit" value="Sign in"/>
        </form>
        <p className = "Signin--p">No Account? <Link to="/auth/register">Sign up now</Link></p>
    </div>

    )
}