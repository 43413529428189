export const validateMail = (mail) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(mail);
};

export const formatDate = (date) => {
  const currentDate = new Date(date);
  const yyyy = currentDate.getFullYear();
  let mm = currentDate.getMonth() + 1;
  let dd = currentDate.getDate();

  const formattedDate = `${dd.toString().padStart(2, '0')}/${mm.toString().padStart(2, '0')}/${yyyy}`;
  return formattedDate;
}