import { styled } from "@mui/system";
import useChat from "../../../Hooks/useChat"
import { Typography } from "@mui/material";
import { Messages } from "./Messages";

const MainContainer = styled("div")({
  backgroundColor: "#36393f",
  height:"100%",
});

const Wrappper = styled("div")({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign:"center",
  marginTop:"20%"
});
export const Messanger = ({setMessage}) => {

  const { chosenChat } = useChat();
  return (
    <MainContainer>
      {!chosenChat ? (<WelcomeMessage />) : 
          <Messages setMessage={setMessage}/>
      }
      

    </MainContainer>
  )
}

const WelcomeMessage = () =>{

  return (
    <Wrappper>
      <Typography variant="h6" sx={{color: "white",textAlign:"center",padding:"0.5rem", fontSize:{xs:"1.2rem",sm:"1.5rem",lg:"2rem"}}}>
        To start chatting, please choose a conversation
      </Typography>
    </Wrappper>
  )
}