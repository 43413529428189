import { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { CustomInput } from '../../Form/CustomInput';
import { CustomPrimaryButton } from '../Button/CustomPrimaryButton';
import useChat from '../../../Hooks/useChat';

export const CreateGroupDialog = ({
    isDialogOpen,
    closeDialogHandler,
}) => {
    const [groupName, setGroupName] = useState('');
    const { createGroup } = useChat();

    const handleCreateNewGroup = () => {
        // send friend request to server
        createGroup({
            conversationName: groupName,
        },
        closeDialogHandler
        );
        
    }

    const handleCloseDialog = () => {
        closeDialogHandler();
        setGroupName('');
    }

    return (
        <div>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} >
                <DialogTitle>
                    <Typography sx={{fontWeight: 'bold',fontSize: 32}}>
                        Customize your server!
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{display:"flex",flexDirection:"column",gap:2}}>
                    {/* <DialogContentText> */}
                        {/* <Typography>
                            Servers' Name
                        </Typography> */}
                    {/* </DialogContentText> */}
                    <CustomInput
                        label='Your Server Name here'
                        type='text'
                        value={groupName}
                        setValue={setGroupName}
                        placeholder="Enter a name for your server"
                    />
                </DialogContent>
                <DialogActions>
                    <CustomPrimaryButton
                        onClick={handleCreateNewGroup}
                        disabled={!groupName}
                        label='Create'
                        additionalStyles={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "10px",
                        }}   
                    />
                </DialogActions>
            </Dialog>
        </div>
    )
}
