import { useContext } from 'react';
import FriendsContext from '../Context/FriendsContext';

const useFriends = () => {
    const friendsContext = useContext(FriendsContext)
    if (!friendsContext) {
        throw new Error('useFriends must be used within a FriendsContext');
    }
    return friendsContext;
}

export default useFriends;