import { friendData } from "../../../dummy/friendData";
import { styled, Button, Typography } from "@mui/material";
import { Avatar } from "../Avatar/Avatar";
import useChat from "../../../Hooks/useChat";
import OnlineIndicator from "../Avatar/OnlineIndicator";
import useFriends from "../../../Hooks/useFriends";
import { useMemo } from "react";
const OnlineUserListMainContainer = styled('div')({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    gap: 10,
    marginTop: "0.5rem",
});
export const OnlineUserList = ({disabled = false}) => {
    const {participants} = useChat();
    const { onlineUsers} = useFriends();
    const checkOnlineUsers = (participants = [], onlineUsers = []) => {
        // iterate friends array
        participants.forEach( participant => {
          // match online users array id with friends
          const isUserOnline = onlineUsers.some(user => user.userId ===  participant["participant.id"]);
          participant.isOnline = isUserOnline;
        });
        return participants;
    };
    const newParticipantsLists = useMemo(() => checkOnlineUsers(participants,onlineUsers), [participants,onlineUsers]);
    return (
        <OnlineUserListMainContainer>
            {newParticipantsLists.map(item => (
                <OnlineUserItem
                    username={item["participant.name"]}
                    id={item["participant.id"]}
                    key={item["participant.id"]}
                    isOnline={item.isOnline}
                    disabled = {disabled}
                />
            ))}
        </OnlineUserListMainContainer>
    )
}

const OnlineUserItem = ({ username, id, isOnline, disabled }) => {
    const handleChooseActiveConversation = () => {
        console.log(id);
    }
    return (
        <Button
            disabled = {disabled}
            onClick={handleChooseActiveConversation}
            style={{
                width: "100%",
                height: "42px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                color: "black",
                position: "relative",
                gap: 12
            }}>
            <div sx={{ position: "relative" }}>
                <Avatar username={username} />
                {isOnline && <OnlineIndicator />}
            </div>
            <Typography
                style={{
                    fontWeight: 600,
                    color: '#8e9297',
                    fontSize: '1rem',
                }}
                variant='subtitle1'
                align='left'
            >
                {username}
            </Typography>

        </Button>
    )

}