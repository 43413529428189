import { useState,createContext } from "react"

const AlertsContext = createContext();

export const AlertsProvider = ({children}) =>{
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [alertMessageContent, setAlertMessageContent] = useState(null);

    const openAlertMessage = (content) =>{
        setShowAlertMessage(true);
        setAlertMessageContent(content)
    }

    const closeAlertMessage = () =>{
        setShowAlertMessage(false);
        setAlertMessageContent(null);
    }

    return(
        <AlertsContext.Provider value = {{showAlertMessage, alertMessageContent, openAlertMessage, closeAlertMessage}}>
            {children}
        </AlertsContext.Provider>
    )
}

export default AlertsContext;
