import { Avatar } from "../Avatar/Avatar"
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
const MainContainer = styled('div')({
    width: "98%",
    display: "column",
    marginTop: "10px",
});

export const MessagesHeader = ({name}) => {
    return (
        <MainContainer>
            <Avatar large username={name} />
            <Typography
                variant = "h4"
                sx={{
                    fontWeight: "bold",
                    color: "white",
                    marginLeft:"5px",
                    marginRight: "5px",
                    marginTop: "10px",
                }}
            >
                {name}
            </Typography>
            <Typography
            sx={{
                color: "#b9bbb0",
                marginLeft:"5px",
                marginRight: "5px",
                marginTop: "10px",
            }}>
                This is the beginning of your conservation with {name}
            </Typography>
        </MainContainer>
    )
}
