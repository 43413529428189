import { styled } from '@mui/system'
import { SideBar } from '../components/Elements/Layout/SideBar';
import { FriendsSideBar } from '../components/Elements/Layout/FriendsSideBar';
import { Messanger } from '../components/Elements/Messanger/Messanger';
import { AppBar } from '../components/Elements/Layout/AppBar';
import { useEffect,useState} from 'react';
import { connectToSocketServer, getSocket } from '../socket';
import useFriends from '../Hooks/useFriends';
import useChat from "../Hooks/useChat";
import useUser from '../Hooks/useUser';
import { Box, Drawer } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useChatHelper } from '../Hooks/useChatHelper';
import { NewMessageInput } from '../components/Form/NewMessageInput';

const Wrapper = styled("div")({
    width: "100%",
    height:"100vh",
    display: "flex",
    padding:0,
    overflowY: 'hidden'
});

const MsgWrapper = styled("div")({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "hidden",
    justifyContent: "space-between",
    position:"relative"
});

export default function Home() {
    const { userInfo, isLoggedin, sideBar, setSideBar } = useUser();
    const { addPendingFriendsInvitations, addFriends, addOnlineUsers } = useFriends();
    const { checkUserinChat, checkUserinGroup } = useChatHelper();
    const { chosenChat, setAvailableRooms } = useChat();
    const phoneWidth = useMediaQuery('(min-width:600px)');
    const [message,setMessage] = useState("");

    useEffect(() => {
        if (isLoggedin) {
            const socket = connectToSocketServer(userInfo);
            socket.on('connect', () => {
                // event when socket received io emitted connect
                console.log('successfully connected');
                console.log(socket.id)
            });
            socket.on('online-users', (data) => {
                // event when socket received io emitted online-users
                const { onlineUsers } = data;
                console.log("online users emit came");
                console.log(onlineUsers);
                addOnlineUsers(onlineUsers);
            });
            socket.on('friends-invitations', (data) => {
                // event when socket received io emitted friends-invitations
                const { pendingInvitations } = data;
                console.log("friends invitation emit came");
                addPendingFriendsInvitations(pendingInvitations);
            });
            socket.on('friends-list', (data) => {
                // event when socket received io emitted friends-list
                const { friends } = data;
                console.log("friends lists emit came");
                console.log(friends);
                // console.log(friends);
                addFriends(friends);
            });

            socket.on('conversation-list', (data) =>{
                console.log('conversation-list emit came');
                console.log(data);
                setAvailableRooms(data.conversationList);
            })
            // socket.on('conversation-users', (data) => {
            //     console.log('conversation-users emit came');
            //     console.log(data);
            //     setParticipants(data.participants);
            // })
            socket.on('conversation-invitations', (data) =>{
                const { pendingInvitations } = data;
                console.log("conversation-invitations emit came");
                console.log(data);
                addPendingFriendsInvitations(pendingInvitations);
            });
            return () => {
                socket.off('connect');
                socket.off('friends-invitations');
                socket.off('friends-list');
                socket.off('online-users');
                socket.off('conversation-list');
                socket.off('conversation-invitations');
                
            }
        }
    }, []);

    useEffect(() => {
        if (isLoggedin) {
            const socket = getSocket();
            socket.on('direct-chat-history', (data) => {
                console.log("Direct-chat-history emit came")
                console.log(data);
                checkUserinChat(data);
            });
            socket.on('conversation-chat-history', (data) =>{
                console.log("conversation-chat-history emit came");
                console.log(data);
                checkUserinGroup(data);
            });
            return () => {
                socket.off('direct-chat-history');
                socket.off('conversation-chat-history');
            }
        }
    }, [userInfo,chosenChat,isLoggedin]);


    return (
        <Wrapper>            
            {phoneWidth ? 
            (
                <Box sx={{ display: 'block' }}>
                    <SideBar />
                </Box>
            ) : 
            (
                // This is the drawer, if phone width is less than 600px, a drawer will be avaialable for use
                <Drawer anchor='left' sx={{height:100}} open={sideBar} onClose={() => setSideBar(false)} >
                    <SideBar />
                </Drawer>
            )}
            <FriendsSideBar />
            {/* Responsible for mobile responsiveness. If a chat is chosen and phone width is less than 600, messagner will be absolute and mount ontop of everything */}
            <Box
                sx={{
                    flexGrow: 1,
                    position: chosenChat && !phoneWidth ? "absolute" : "relative",
                    width: chosenChat && !phoneWidth ? "100%" : "100%",
                    height: chosenChat && !phoneWidth ? "100%" : "100%",
                }}
            >
                <MsgWrapper>
                    <AppBar />
                    <Messanger />
                    {chosenChat && <NewMessageInput message={message} setMessage={setMessage}/>}
                </MsgWrapper>
            </Box>

        </Wrapper>
    )
}