import Views from './Routes/views';
import { AlertNotification } from './components/Elements/Alerts/AlertNotification';
function App() {
  return (
      <>
      <Views/>
      <AlertNotification />
      </>
  );
}

export default App;
