import useChat from './useChat'
import useUser from './useUser'

export const useChatHelper = () => {
    // check whether users in the participants array. If so, update messages
    const { chosenChat, setMessages, setParticipants} = useChat();
    const { userInfo } = useUser();
    const checkUserinChat = (data) => {
        const { participants, messages: M } = data;
        const receiverId = chosenChat?.id;
        const senderId = userInfo.id;

        if (receiverId && senderId) {
            const usersInConversation = [receiverId, senderId];
            const foundParticipants = participants.every((participant) => {
                return usersInConversation.includes(participant["participant.id"]);
            });
            console.log(foundParticipants);
            if (foundParticipants) {
                setMessages(M);
            }
        }
    }

    const checkUserinGroup = (data) => {
        const { participants, messages: M } = data;
        // extract essential info
        const conId = chosenChat.id;
        const senderId = userInfo.id
        // console.log(participants);
        setParticipants(participants);
        // conversationid and senderid exists
        if(conId && senderId){
            // const foundParticipants = participants.every((participant) =>{
            //     return [senderId].includes(participant["participant.id"]);
            // })
            const foundParticipants = participants.some((participant) => participant["participant.id"] === senderId);
            console.log(foundParticipants);
            if(foundParticipants){
                
                setMessages(M);
            }
        }
    }
    return {
        checkUserinChat,
        checkUserinGroup
    };
}