import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import useChat from '../../../Hooks/useChat';
export const ArrowBackButton = () =>{
    const { setChosenChat } = useChat();
    const handleClick = () =>{
      setChosenChat(null);
    }
    return(
      <IconButton onClick={handleClick}>
        <ArrowBackIcon sx={{color:"white"}}/>
      </IconButton>
    )
  }