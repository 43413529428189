import {styled} from '@mui/system'
import Button from '@mui/material/Button';
import GroupIcon from '@mui/icons-material/Groups';
import { CreateRoomButton } from '../Button/CreateRoomButton';
import { GroupButton } from '../Button/GroupButton';
import useChat from '../../../Hooks/useChat';

const MainContainer = styled('div')({
    maxWidth:"55px",
    width:"100%",
    height:"100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#202225",
    padding:"0.2rem 0.8rem",
})

export const SideBar = () => {
  const { availableRooms } = useChat();
  // console.log(availableRooms);
  return (
    <MainContainer>
        <MainPageButton />
        {availableRooms.map((room) => (
          <GroupButton
            roomId={room.ConversationConid}
            name={room.conversation.name}
            key={room.ConversationConid}
          />
      ))}
        <CreateRoomButton />
    </MainContainer>
  )
}

const MainPageButton = () => {
    const {setChosenChat, setChatType} = useChat();
    const handleClick = () =>{
      setChosenChat(null);
      setChatType(null);
    }
    return(
      <Button 
        onClick={handleClick}
        style={{
        width: '48px',
        height: '48px',
        borderRadius: '16px',
        margin:0,
        padding:"0.4rem",
        minWidth:0,
        marginTop: "10px",
        color: "white",
        backgroundColor: '#5865F2',
        }}
      >
          <GroupIcon />
      </Button>
    )
}