import { useState, createContext} from "react"
import { axiosPrivate } from "../api/axios";

const RoomsContext = createContext();

export const RoomsProvider = ({children}) =>{
    const [roomDetails, setRoomDetails] = useState(null);
    const [existingRooms, setExistingRooms] = useState([]);

    const values = {
        roomDetails, 
        setRoomDetails,
        existingRooms, 
        setExistingRooms
    }
    return(
        <RoomsContext.Provider value = {values}>
            {children}
        </RoomsContext.Provider>
    )
}

export default RoomsContext;