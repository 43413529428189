import { Box } from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function OnlineIndicator() {
  return (
    <Box sx={{
        color:"green",
        display: "flex",
        alignItems: "center",
        position:"absolute",
        top:"35px",
        bottom:0,
        left:"35px",
        right:0,
        }}>
        <FiberManualRecordIcon />
    </Box>
  )
}
