import MenuIcon from '@mui/icons-material/Menu';
import useUser from '../../../Hooks/useUser';
import { IconButton } from '@mui/material'

export const MenuButton = () =>{
    const {sideBar, setSideBar} = useUser();
    const handleClick = () =>{
      console.log(sideBar);
      setSideBar(true);
    }
    return(
      <IconButton sx={{ display: { xs: 'block', sm: 'none', md: 'none' }}} onClick={handleClick}>
        <MenuIcon sx={{flexGrow:1,color:"white"}}/>
      </IconButton>
    )
  }