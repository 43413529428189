import { IconButton, Drawer, Box, Typography } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { useState } from 'react';
import { OnlineUserList } from '../List/OnlineUserList';
import { FriendsTitle } from '../Layout/FriendsTitle';

export const GroupIconButton = () =>{
    const [drawer,setDrawer] = useState(false);
    const handleClick = () =>{
      setDrawer(true);
    }
    return(
      <>
      <IconButton onClick={handleClick}>
        <GroupIcon sx={{color:"white"}}/>
      </IconButton>
      {
        drawer &&
        <Drawer anchor='left' open={drawer} onClose={() => setDrawer(false)}>
          <Box
          sx={{
            display:"flex",
            flexDirection:"column",
            backgroundColor:"#36393f",
            height:"100vh",
            alignItems:"center",
            padding:"0.5rem",
          }}>
            <FriendsTitle title='USERS IN THE GROUP' />
            <OnlineUserList disabled={true}/>
          </Box>
        </Drawer>
      }

      </>
    )
  }