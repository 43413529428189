import { useContext } from 'react';
import AlertsContext from '../Context/AlertsContext';

const useAlerts = () => {
    const alertsContext = useContext(AlertsContext);
    if (!alertsContext) {
        throw new Error('useUser must be used within a UserContext');
    }
    return alertsContext;
}

export default useAlerts;
