import { Alert, Snackbar } from "@mui/material"
import useAlerts from "../../../Hooks/useAlerts";
export const AlertNotification = () => {
    const {showAlertMessage, alertMessageContent, closeAlertMessage} = useAlerts();
    // alertMessageContent is a string state from AlertsProvider
    // showAlertMessage and closeAlertMessage is setting the boolean 
    return (
      <Snackbar
          anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
          }}
          open={showAlertMessage}
          onClose={closeAlertMessage}
          autoHideDuration={6000}
      >
          <Alert severity="info">{alertMessageContent}</Alert>
      </Snackbar>
    )
}
