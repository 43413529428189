import { useContext } from 'react';
import ChatsContext from '../Context/ChatContext';

const useChat = () => {
    const chatsContext = useContext(ChatsContext);
    if (!chatsContext) {
        throw new Error('useUser must be used within a ChatContext');
    }
    return chatsContext;
}

export default useChat;
