import { Avatar } from "../Avatar/Avatar";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { formatDate } from "../../../utils/validators";

const MainContainer = styled("div")({
    width: "97%",
    display: "flex",
    marginTop: "10px",
});

const AvatarContainer = styled("div")({
    width: "70px",
});

const MessageContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
});

const MessageContent = styled("div")({
    color: "#DCDDDE",
});

const SameAuthorMessageContent = styled("div")({
    color: "#DCDDDE",
    width: "97%",
});

const SameAuthorMessageText = styled("span")({
    marginLeft: "70px",
});

export const Message = ({ content, sameAuthor, username, date, sameDay }) => {
    const formattedDate = formatDate(date);
    if (sameAuthor && sameDay) {
        return (
            <SameAuthorMessageContent>
                <SameAuthorMessageText>
                    {content}
                </SameAuthorMessageText>
            </SameAuthorMessageContent>
        )
    }
    return (
        <MainContainer>
            <AvatarContainer>
                <Avatar username={username} />
            </AvatarContainer>
            <MessageContainer>
                <Typography style={{ fontSize: '16px', color: 'white' }}>
                    {username}{" "}
                    <span style={{ fontSize: "12px", color: "#72767d" }}>
                        {formattedDate}
                    </span>
                </Typography>
                <MessageContent>
                    {content}
                </MessageContent>
            </MessageContainer>
        </MainContainer>
    )
}