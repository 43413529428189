import { useState } from 'react'
import { AddFriendDialog } from '../Dialog/AddFriendDialog'
import { CustomPrimaryButton } from '../Button/CustomPrimaryButton'

export const AddFriendButton = () =>{
    const additionalStyles = {
      width: '80%',
      background: '#3ba55d',
    }
    const [isDialogOpen, setIsDialogOpen] = useState(false);
  
    const handleOpenAddFriendDialog = () => {
      setIsDialogOpen(true);
    };
  
    const handleCloseAddFriendDialog = () =>{
      setIsDialogOpen(false);
    }
  
    return (
      <>
        <CustomPrimaryButton 
          additionalStyles={additionalStyles}
          label="Add Friend"
          onClick={handleOpenAddFriendDialog}
        />
        <AddFriendDialog
          isDialogOpen = {isDialogOpen}
          closeDialogHandler = {handleCloseAddFriendDialog}
        />
      </>
    )
  }