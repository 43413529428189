import { Button, Tooltip } from "@mui/material";
import { Avatar } from "../Avatar/Avatar";
import useChat from "../../../Hooks/useChat";
import { getGroupParticipants } from "../../../socket";
import { getConversationChatHistory } from "../../../socket";
export const GroupButton = ({ roomId, name }) => {
    const {SetChosenChat } = useChat();
    const handleOpenRoom = () =>{
        // emit socket to get grp participants
        // getGroupParticipants({id:roomId});
        // emit socket to get chat history
        getConversationChatHistory({conId:roomId});
        SetChosenChat({id:roomId, name:name, type:"GROUP"});
        
        // console.log(chosenChat);
    }
    return (
        <Tooltip title={name}>
            <div>
                <Button
                    style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "16px",
                        margin: 0,
                        padding: 0,
                        minWidth: 0,
                        marginTop: "10px",
                        color: "white",
                        backgroundColor: "#5865F2",
                    }}
                    onClick={handleOpenRoom}
                >
                    <Avatar username={name} />
                </Button>
            </div>
        </Tooltip>
    )
}
