import useChat from '../../../Hooks/useChat';
import OnlineIndicator from '../Avatar/OnlineIndicator';
import { Button, styled, Typography } from '@mui/material';
import useFriends from '../../../Hooks/useFriends';
import { Avatar } from '../Avatar/Avatar';
import { useMemo } from 'react';
import { getDirectChatHistory } from '../../../socket';
import { friendData } from '../../../dummy/friendData';
const FriendsListMainContainer = styled('div')({
    display:"flex",
    flexDirection:"column",
    flexGrow:1,
    width:"100%",
    gap:10,
    marginTop:"0.5rem",
});

export const FriendsList= () =>{

    // get global state friends and online users
    const { friends, onlineUsers} = useFriends();
    // console.log(friends);
    // console.log(onlineUsers);
    // check onlineUsers, take in friends and onlineUsers, output array of username,id,key and isonline
    const checkOnlineUsers = (friends = [], onlineUsers = []) => {
        // iterate friends array
        friends.forEach(friend => {
          // match online users array id with friends
          const isUserOnline = onlineUsers.some(user => user.userId === friend.friendId);
          friend.isOnline = isUserOnline;
        });
        return friends;
      };
    // checkOnlineUsers may take long. So usememo is used to cache the information and only rerender when necessary
    // const newfriendLists = checkOnlineUsers(friends,onlineUsers);
    const newfriendLists = useMemo(() => checkOnlineUsers(friends,onlineUsers), [friends,onlineUsers])
    // console.log(newfriendLists);
    // console.log(friendData);
    return(
      <FriendsListMainContainer>
        {newfriendLists.map(item => (
            <FriendsListItem 
            username={item.friendUser.name}
            id={item.friendUser.id}
            key={item.id}
            isOnline={item.isOnline}
            />
        ))}

      </FriendsListMainContainer>
    )
}

const FriendsListItem = ({username,id,isOnline}) => {
    const {SetChosenChat} = useChat()
    const handleChooseActiveConversation = () =>{
        SetChosenChat({id:id, name:username,type:"DIRECT"});
        getDirectChatHistory({
            receiverUserId: id,
        });
    }
    return (
        <Button
            onClick={handleChooseActiveConversation}
            style={{
                width:"100%",
                height:"42px",
                display:"flex",
                alignItems:"center",
                justifyContent:"flex-start",
                color:"black",
                position: "relative",
                gap:12
            }}>
                <div sx={{position:"relative"}}>
                    <Avatar username={username}/>
                    {isOnline && <OnlineIndicator/>}
                </div>
                <Typography
                    style={{
                        fontWeight: 600,
                        color: '#8e9297',
                        fontSize:'1rem',
                    }}
                    variant='subtitle1'
                    align='left'
                >
                    {username}
                </Typography>
                
        </Button>
    )
}