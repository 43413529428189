import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useUser from "../Hooks/useUser";

export default function Register() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      //data have email and password
      console.log("Registering")
      const response = await axios.post("auth/register",
        JSON.stringify(data),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(response);
      navigate("/auth/login");

    }
    catch (error) {
      console.log(error);
      if(error.response.data.error.includes("Email")){
        setError("email", {
          type: "manual",
          message: error.response.data.error
      });
    }

      if(error.response.data.error.includes("Username")){
        setError("name", {
          type: "manual",
          message: error.response.data.error
      });
      }
    }
  };

  return (
    <div className="Signin">
      <h1 className="Signin--h1"> Create your account</h1>
      <form className="Signin--form" onSubmit={handleSubmit(onSubmit)}>
        <label>Email</label>
        <input className="Signin--text" type="email" name="email" placeholder="Enter your email" {...register("email", { required: true })} />
        {errors?.email?.type === "required" && <p className="Signin--error">This field is required</p>}
        {errors?.email?.message && <p className="Signin--error">{errors?.email?.message}</p> }

        <label>Username</label>
        <input className="Signin--text" type="text" name="name" placeholder="Enter your username" {...register("name", { required: true, maxLength: 20 })} />
        {errors?.name?.type === "required" && <p className="Signin--error">This field is required</p>}
        {errors?.name?.message && <p className="Signin--error">{errors?.name?.message}</p> }


        <label>Password</label>
        <input className="Signin--text" type="password" name="password" placeholder="Enter your password" {...register("password", { required: true, minLength: 8 })} />
        {errors?.password?.type === "required" && <p className="Signin--error">This field is required</p>}
        {errors?.password?.type === "minLength" && <p className="Signin--error">Your Password must be more than 8 characters</p>}

        <label>Confirm Password</label>
        <input className="Signin--text" type="password" name="cpassword" placeholder="Confirm your password" {...register("cpassword", {
          required: true,
          minLength: 8,
          validate: (value) => {
            const { password } = getValues();
            return password === value || "Password must be matching!"
          }
        })} />
        {errors?.cpassword?.type === "required" && <p className="Signin--error">This field is required</p>}
        {errors?.cpassword?.type === "minLength" && <p className="Signin--error">Your Password must be more than 8 characters</p>}
        {errors?.cpassword?.type === "validate" && <p className="Signin--error">Password must be matching!</p>}
        <input className="Signin--submit" type="submit" value="Sign up" />
      </form>
      <p>Already have an account? <Link to="/auth/login">Sign in now</Link></p>
    </div>

  )
}