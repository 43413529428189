import {Routes, Route} from "react-router-dom";

import Signin from "../Pages/Signin";
import Home from "../Pages/Home"
import NotFound from "../Pages/NotFound"
import Register from "../Pages/Register";
import { PrivateRoute } from "../components/PrivateRoute"

export default function Views(){

    return (
        <>
            <Routes>
                <Route element = {<PrivateRoute />}>
                    <Route path = "/" element = {<Home />} />
                </Route>
                <Route path = "*" element = {<NotFound />} /> 
                <Route path ="/auth/login" element ={<Signin />} />
                <Route path ="/auth/register" element ={<Register />} />
                {/* <Route path ="/auth/reset" element ={<ResetPassword />} /> */}
            </Routes>
        </>
    )

}