import {styled} from '@mui/system'
// import { friendData,invitationData } from '../../../dummy/friendData';
import { Tooltip, Typography, Box } from '@mui/material';
import { Avatar } from '../Avatar/Avatar';
import { useState } from 'react';
import { InvitationDecisionButtons } from '../Button/InvitationDecisionButtons';
import useFriends from '../../../Hooks/useFriends';

const PendingInvitationsListMainContainer = styled('div')({
    width:"100%",
    height: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    overflow: "auto",
});

export const PendingInvitationsList= () =>{

    const {pendingFriendsInvitations} = useFriends();
    return(
      <PendingInvitationsListMainContainer>
        {pendingFriendsInvitations.map(item => (
            <PendingInvitationsListItem 
                key={item?.id}
                id={item?.id}
                username={item?.senderUser?.name}
                mail={item?.senderUser?.email}
            />
        ))}
      </PendingInvitationsListMainContainer>
    )
}

const PendingInvitationsListItem = ({
    id,
    username,
    mail,
}) =>{
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const {acceptFriendInvitation, rejectFriendInvitation,removePendingFriendsInvitations} = useFriends();

    const handleAcceptInvitation = () => {
        acceptFriendInvitation({id:id});
        setButtonsDisabled(true);
        removePendingFriendsInvitations(id);
    }

    const handleRejectInvitation = () => {
        rejectFriendInvitation({id:id});
        setButtonsDisabled(true);
        removePendingFriendsInvitations(id);
    }
    // width:"100%",
    // height:"42px",
    // display:"flex",
    // alignItems:"center",
    // justifyContent:"flex-start",
    // color:"black",
    // position: "relative",
    // 
    return (
        <div sx={{width:'100%',}}>
            <Tooltip title = {mail}>
                <Box sx ={{
                    width: '100%',
                    height: '42px',
                    marginTop: '10px',
                    display:"flex",
                    alignItems:"center",
                }}>
                    <Avatar username = {username} />
                    <Typography sx={{
                        marginLeft: '10px',
                        fontWeight: 700,
                        color: '#8e9297',
                        flexGrow: 1
                    }}
                    variant='subtitle1'
                    >
                        {username}
                    </Typography>
                    <InvitationDecisionButtons 
                        disabled = {buttonsDisabled}
                        acceptInvitationHandler = {handleAcceptInvitation}
                        rejectInvitationHandler = {handleRejectInvitation}
                    />
                </Box>

            </Tooltip>
        </div>
    )

}