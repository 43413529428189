import { Typography } from '@mui/material'

export const FriendsTitle = ({title}) => {
    return (
      <Typography
        sx={{
          textTransform: 'uppercase',
          color: '#8e9297',
          fontSize: {lg: '1rem',sm:'0.8rem',xs:'0.8rem'},
          textAlign: "center",
          marginTop: '10px',
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
    )
  }