import { MessagesHeader } from "./MessagesHeader";
import useChat from "../../../Hooks/useChat";
import styled from "@emotion/styled";
import { Message } from "./MessageItem";
import { useRef, useEffect } from "react";

const MessagesContainer = styled("div")({
    minheight: "400px",
    height: "calc(100% - 105px)",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom:"1rem"
});

export const Messages = ({setMessage}) => {
    const { messages, chosenChat } = useChat();

    const messagesContainerRef = useRef(null);
    const userScrolledUpRef = useRef(false);

    let prevTimestampDate = null;
    var prevAuthor = "";
    const cleanMessageArray = messages.map((message) => {
        const timestampDate = new Date(message.createdAt);
        let sameDay = false;

        // check if prevTimestampDate exist
        if(prevTimestampDate){
            sameDay = (
                timestampDate.getDate() === prevTimestampDate.getDate() &&
                timestampDate.getMonth() === prevTimestampDate.getMonth() &&
                timestampDate.getFullYear() === prevTimestampDate.getFullYear()
            );
        }
        prevTimestampDate = timestampDate;
        // console.log(prevAuthor);
        const isSameAuthor = message["author.name"] === prevAuthor;
        prevAuthor = message["author.name"];
        // console.log(message["author.name"]);
        // console.log("---");
        // console.log(prevAuthor);
        return {
            ...message,
            sameDay: sameDay,
            sameAuthor: isSameAuthor,
            createdAt: new Date(message.createdAt),
            updatedAt: new Date(message.updatedAt),
        }

    });
    // console.log(cleanMessageArray);

    useEffect(()=>{
        const chatContainer = messagesContainerRef.current;
        // Total height of container - visible height of container <= current scroll position from top
        const isScrolledToBottom = chatContainer.scrollHeight - (chatContainer.scrollTop + 2) <= chatContainer.clientHeight ;
        if(!userScrolledUpRef.current || isScrolledToBottom){
            chatContainer.scrollTop = chatContainer.scrollHeight;
            userScrolledUpRef.current = false;
        }
    },[cleanMessageArray]);

    const onScrollHandler = (e) =>{
        const chatContainer = e.target;
        // console.log(chatContainer.scrollTop);
        // console.log(chatContainer.scrollHeight);
        // console.log(chatContainer.clientHeight);
        const isScrolledToBottom = chatContainer.scrollHeight - (chatContainer.scrollTop + 1) <= chatContainer.clientHeight ;
        console.log(isScrolledToBottom);
        if (!isScrolledToBottom) {
            // User has scrolled up, set the userScrolledUpRef to true
            userScrolledUpRef.current = true;
        }
    }
    return (
        <MessagesContainer ref={messagesContainerRef} onScroll={onScrollHandler} >
            {cleanMessageArray.length === 0 && <MessagesHeader name={chosenChat.name} />}
            {cleanMessageArray.map((message) => {
                return (<Message
                    key={message.messageid}
                    content={message.content}
                    username={message["author.name"]}
                    date={message.updatedAt}
                    sameDay={message.sameDay}
                    sameAuthor={message.sameAuthor}
                />)
            })}
        </MessagesContainer>
    )
}
