import { createContext, useMemo, useRef, useState } from 'react';
import { axiosPrivate} from "../api/axios"

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userInfo,SetUserInfo] = useState({
    id:0,
    name:"",
    pic:"",
    isAdmin:false,
    email:"",
  });
  const [isLoading, SetIsLoading] = useState(false);
  const [isLoggedin, SetIsLoggedin] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  //ensure usememo or useeffect runs once during developmenet
  const runOnceRef = useRef(false);

  const getUserDetail = async (signal) =>{
    try{
        SetIsLoading(true);
        const response = await axiosPrivate.get("/auth/protected",{signal:signal});
        console.log("Fetched userDetails")
        SetIsLoggedin(true);
        SetUserInfo({
          id:response.data.id,
          name:response.data.name,
          pic:response.data.pic,
          isAdmin:response.data.isAdmin,
          email:response.data.email,
        })
        SetIsLoading(false);
    }
    catch(err){
        SetIsLoading(false);
        console.log(err);
    }
  }

  useMemo(() => {
    const abortController = new AbortController();
    const signal = abortController.signal
    if(!runOnceRef.current){
      getUserDetail(signal);
      runOnceRef.current = true;
    }
    return () =>{
      abortController.abort();
    }
  }, []);
  
  return (
    <UserContext.Provider value={{ userInfo,SetUserInfo,isLoading,isLoggedin, SetIsLoggedin,SetIsLoading,sideBar,setSideBar }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
