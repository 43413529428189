import React, { useState } from 'react'
import { Button } from '@mui/material'
import AddIcon from "@mui/icons-material/Add";
import useRoom from '../../../Hooks/useRoom';
import { CreateGroupDialog } from '../Dialog/CreateGroupDialog';

export const CreateRoomButton = () => {
    const { isUserInRoom } = useRoom();
    const [isDialogOpen,setIsDialogOpen] = useState(false);
    const handleOpenCreateGroupDialog = () => {
        setIsDialogOpen(true);
      };
    
      const handleCloseCreateGroupDialog = () =>{
        setIsDialogOpen(false);
      }
    return (
        <>
        <Button
            disabled={isUserInRoom}
            onClick={handleOpenCreateGroupDialog}
            style={{
                width: "48px",
                height: "48px",
                borderRadius: "16px",
                margin: 0,
                padding: 0,
                minWidth: 0,
                marginTop: "10px",
                color: "white",
                backgroundColor: "#5865F2",
            }}
        >
            <AddIcon />
        </Button>
        <CreateGroupDialog isDialogOpen={isDialogOpen} closeDialogHandler={handleCloseCreateGroupDialog}/>
        </>
    )
}
