import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import { UserProvider } from './Context/UserContext';
import { FriendsProvider } from './Context/FriendsContext';
import { AlertsProvider } from './Context/AlertsContext';
import { ChatsProvider } from './Context/ChatContext';
import { RoomsProvider } from './Context/RoomContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <RoomsProvider>
        <AlertsProvider>
          <FriendsProvider>
            <ChatsProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ChatsProvider>
          </FriendsProvider>
        </AlertsProvider>
      </RoomsProvider>
    </UserProvider>
  </React.StrictMode>

);

