import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { axiosPrivate } from "../../../api/axios";
import useUser from "../../../Hooks/useUser";

export const DropdownMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { SetIsLoggedin } = useUser();
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = async () =>{
    try{
        await axiosPrivate("/auth/logout",{
          method:"post"
        });
        console.log("Successfully logged out");
        SetIsLoggedin(false);
    }
    catch(err){
        console.log(err);
    }
  }

  return (
    <div>
      <IconButton onClick={handleMenuOpen} style={{ color: "white" }}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={logout}>Sign out</MenuItem>
      </Menu>
    </div>
  );
};