import {io} from "socket.io-client"
let socket = null
export const connectToSocketServer = (userInfo) =>{
    socket = io(process.env.REACT_APP_ORIGIN_URL,{
        withCredentials:true,
        auth:{
            userInfo:userInfo
        }
    });
    return socket;
};

export const getSocket = () =>{
    return socket;
}

// direct message emission
export const sendDirectMessage = (data) =>{
    // check if socket is connected and not null
    if(socket!==null && socket.connected){
        console.log("Socket emitted sendDirectMessage");
        socket.emit("direct-message", data);
    }
}

// get direct chat emission
export const getDirectChatHistory = (data) =>{
    // check if socket is connected and not null
    if(socket!==null && socket.connected){
        console.log("Socket emitted getDirectChatHistory");
        socket.emit("direct-chat-history", data);
    }
}

// group message emission
export const sendGroupMessage = (data) =>{
    // check if socket is connected and not null
    if(socket!==null && socket.connected){
        console.log("Socket emitted sendGroupMessage");
        socket.emit("conversation-message", data);
    }
}

// get group history
export const getConversationChatHistory = (data) =>{
    // check if socket is connected and not null
    if(socket!==null && socket.connected){
        console.log("Socket emitted getDirectChatHistory");
        socket.emit("conversation-chat-history", data);
    }
}