import { useEffect } from "react"
import styled from "@emotion/styled";
import { sendDirectMessage, sendGroupMessage } from "../../socket";
import useChat from "../../Hooks/useChat";

const MainContainer = styled("div")({
  display: "flex",
  width:"inherit",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "auto",
  position: "absolute",
  bottom:10,
});

const Input = styled("input")({
  backgroundColor: "#2f3136",
  width: "98%",
  height: "44px",
  color: "white",
  border: "none",
  borderRadius: "8px",
  fontSize: "14px",
  padding: "0 1rem",
});

export const NewMessageInput = ({message,setMessage}) => {
  const { chosenChat } = useChat();
  const handleMessageValueChange = (event) =>{
    setMessage(event.target.value);
    sessionStorage.setItem('messageInput', message);
  }
  const handleKeyPressed = (event) =>{
    if(event.key === "Enter"){
      handleSendMessage();
    }
  }

  const handleSendMessage = () =>{
    console.log("sending message to the server");
    if(message.length > 0){
      if(chosenChat.type === "DIRECT"){
        //socket emit for direct message
        sendDirectMessage({
          receiverUserId: chosenChat?.id,
          content: message
        });
      }
      if(chosenChat.type === "GROUP"){
        // socket emit for group message
        sendGroupMessage({
          conId:chosenChat?.id,
          content:message
        });
      }
      setMessage("");
      sessionStorage.setItem('messageInput', "");
    }
  }
  useEffect(()=>{
    setMessage(sessionStorage.getItem('messageInput'));
  },[]);

  return (
    <MainContainer>
      <Input 
        placeholder={`Write message to ${chosenChat.name}`}
        value={message}
        onChange={handleMessageValueChange}
        onKeyDown={handleKeyPressed}
        />
    </MainContainer>
  )
}
