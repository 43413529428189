import { styled } from '@mui/system'
import { FriendsTitle } from './FriendsTitle'
import { FriendsList } from '../List/FriendList'
import { PendingInvitationsList } from '../List/PendingInvitationList'
import { AddFriendButton } from '../Button/AddFriendButton'
import { Box } from '@mui/material'
import { MenuButton } from '../Button/MenuButton'
import useChat from '../../../Hooks/useChat'
import { OnlineUserList } from '../List/OnlineUserList'
import { InviteFriendButton } from '../Button/InviteFriendButton'
const MainContainer = styled('div')({
  minWidth: "280px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#2F3136",
  gap: 2,
  padding: "0.8rem 0.2rem",
  flexGrow: 1,
})


export const FriendsSideBar = () => {
  const { chosenChat } = useChat();
  // console.log(chosenChat);
  var FTitle;
  if (chosenChat) {
    FTitle = chosenChat?.type === "GROUP" ? "Users in the Group" : "Private Messages";
  }
  else {
    FTitle = "Private Messages";
  }
  return (
    <MainContainer sx={{ flexGrow: { xs: 1, sm: 1, md: 0 } }}>
      <Box sx={
        {
          display: "flex",
          flexShrink: 0,
          flexGrow: 0,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <MenuButton />
        {chosenChat?.type === "GROUP" ? <InviteFriendButton /> : <AddFriendButton />}

      </Box>
      <FriendsTitle title={FTitle} />
      {chosenChat?.type === "GROUP" ? <OnlineUserList /> : <FriendsList />}
      <FriendsTitle title='Invitations' />
      <PendingInvitationsList />
    </MainContainer>
  )
}

