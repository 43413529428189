import { useEffect, useState } from 'react'
import { validateMail } from '../../../utils/validators';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { CustomInput } from '../../Form/CustomInput';
import { CustomPrimaryButton } from '../Button/CustomPrimaryButton';
import  useFriends  from "../../../Hooks/useFriends"

export const AddFriendDialog = ({
    isDialogOpen,
    closeDialogHandler,
}) => {
    const [mail, setMail] = useState('');
    const [isFormValid, setIsFormValid] = useState('');
    const { sendFriendInvitation } = useFriends();

    const handleSendInvitation = () => {
        //send friend request to server
        sendFriendInvitation({
            targetMailAddress: mail,
            type:"DIRECT",
            conId: null
        },
        closeDialogHandler
        );
        
    }

    const handleCloseDialog = () => {
        closeDialogHandler();
        setMail('');
    }

    useEffect(() => {
        setIsFormValid(validateMail(mail))
    }, [mail, setIsFormValid])

    return (
        <div>
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} >
                <DialogTitle>
                    <Typography sx={{fontWeight: 'bold',fontSize: 32}}>
                        Invite a friend
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{display:"flex",flexDirection:"column",gap:2}}>
                    {/* <DialogContentText> */}
                        <Typography>
                            Enter email address of friend which you would like to invite
                        </Typography>
                    {/* </DialogContentText> */}
                    <CustomInput
                        label='Mail'
                        type='text'
                        value={mail}
                        setValue={setMail}
                        placeholder="Enter email address"
                    />
                </DialogContent>
                <DialogActions>
                    <CustomPrimaryButton
                        onClick={handleSendInvitation}
                        disabled={!isFormValid}
                        label='Send'
                        additionalStyles={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginBottom: "10px",
                        }}   
                    />
                </DialogActions>
            </Dialog>
        </div>
    )
}
