import { useContext } from 'react';
import RoomsContext from '../Context/RoomContext';

const useRoom = () => {
    const roomsContext = useContext(RoomsContext);
    if (!roomsContext) {
        throw new Error('useUser must be used within a ChatContext');
    }
    return roomsContext;
}

export default useRoom;
