import { styled } from "@mui/material"

export const Avatar = ({username, large}) => {
    const fontSize = large ? '2rem' : "0.9rem";
    const AvatarPreivew = styled('div')({
        height: '42px',
        width: '42px',
        backgroundColor: '#5865f2',
        borderRadius:'42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: fontSize,
        fontWeight: '700',
        marginLeft: '5px',
        color:'white',
        flexShrink:0,
    });
  return (
    <AvatarPreivew style={large ? {height: '80px', width: '80px'} : {}}>
        {username.substring(0,2)}
    </AvatarPreivew>
  )
}
